import { createContext } from "react";

type AnalyticsContextProps = {
    trackPage: (pageName: string, additionalProps?: any) => void,
    trackFlow: (domain: string, stepName: string) => void,
    trackEvent: (eventName: string, props: any) => void,
    identifyUser: (id: string) => void,
    forgetUser: () => void
};

const AnalyticsContext = createContext<Partial<AnalyticsContextProps>>({});

export default AnalyticsContext;