import Badge from "components/badge/Badge";
import DropdownButton from "components/buttons/DropdownButton";
import PreviewComponent from "components/cards/PreviewComponentProps";
import FilterComponentBuilder from "components/filters/new/FilterComponentBuilder";
import FormElement from "components/forms/FormElement";
import MediaComponent from "components/MediaComponent";
import Spacer from "components/Spacer";
import TableItem from "components/table_v2/components/TableItem";
import SimpleTable from "components/table_v2/SimpleTable";
import TagList from "components/tag/TagList";
import LinkComponent from "components/typography/LinkComponent";
import FormGroup from "components/ui/FormGroup";
import PiggyTooltip from "components/ui/PiggyTooltip";
import React from "react";
import AddOptions from "./AddOptions";
import CalloutCard from "./cards/CalloutCard";
import RadioElement from "components/forms/Duration/RadioElement";
import Text from "./typography/Text";
import DropdownCard from "./cards/DropdownCard";
import FormElementGroup from "components/forms/DisplayFormElements/FormElementGroup";
import Table from "./table_v2/Table";
import DisplayTagElement from "components/forms/DisplayFormElements/DisplayTagElement";
import DisplayBooleanElement from "components/forms/DisplayFormElements/DisplayBooleanElement";
import DisplayMediaElement from "components/forms/DisplayFormElements/DisplayMediaElement";
import DisplayFormElement from "components/forms/DisplayFormElements/DisplayFormElement";
import DraggableList from "components/DraggableList";
import FormMediaPicker from "./media/FormMediaPicker";
import Banner from "components/banner/Banner";
import InfoBanner from "./banner/InfoBanner";
import ButtonComponent from "components/buttons/ButtonComponent";
import MediaPicker from "components/media/MediaPicker";
import cn from "classnames";
import ReadOnlyFiltersPreview from "components/filters/new/ReadOnlyFiltersPreview";
import FormFileUpload from "./files/FormFileUpload";
import Modal from "components/modals/types/Modal";
import PiggyTooltipV2 from "components/ui/PiggyTooltipV2";
import TableProgressBar from "components/TableProgressBar";
import FormRow from "@/components/ui/FormRow";

export type UIComponentMapperProps = {
    element: any;
    onClick?: any;
    onChange?: (name: string, value: any) => void;
    onBlur?: (name: string, value: any) => void;
    data?: any;
    pending?: boolean;
    container?: HTMLElement;
    active?: boolean;
    className?: string;
    barClassName?: string;
    onUpdate?: any;
    mutate?: any;
    onMouseOver?: any;
    onMouseOut?: any;
    filterValues?: any;
    onFilterChange?: any;
    mediaRenderer?: any;
    onMouseUp?: any;
};

export default function UIComponent(props: UIComponentMapperProps) {
    const {
        element,
        onClick,
        onChange,
        onBlur,
        pending,
        container,
        mutate,
        onMouseOver,
        onMouseOut,
        filterValues,
        onFilterChange,
        mediaRenderer,
        onMouseUp,
    } = props;
    const data = props.data ? props.data : {};

    if (!element) {
        console.log("No element found for:", element);
        return null;
    }

    const value = data[element.name] !== undefined ? data[element.name] : element.value;
    const className = cn(props.className);
    const barClassName = cn(props.barClassName);

    if (element.type === "table-progress-bar") {
        return (
            <TableProgressBar
                percentage={element.percentage}
                color={element.color}
                value={element.value}
                total={element.total}
            />
        );
    }

    if (element.type == "tags") {
        return <TagList tags={element.tags} tooltip={element.tooltip}/>;
    }

    if (element.type == "colored_dot") {
        return (
            <span
                style={{
                    backgroundColor: element.color,
                }}
                className={"size-3 rounded-full border"}
            />
        );
    }

    if (element.type == "avatars") {
        return (element.avatars.map((avatar: any) => (
                <PiggyTooltipV2
                    key={avatar.id}
                    id={avatar.id}
                    content={avatar.tooltip}
                >
                    <MediaComponent
                        media={avatar.media}
                        className={
                            "size-10 rounded-full bg-primary-600 text-white"
                        }
                    />
                </PiggyTooltipV2>
            ))
        );
    }

    if (element.type == "callout_card") {
        return (
            <CalloutCard>
                {element.elements.map((e: any) => (
                    <UIComponent
                        key={e.id}
                        element={e}
                        onClick={onClick}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                ))}
            </CalloutCard>
        );
    }

    if (element.type == "form_group") {
        return (
            <FormGroup
                {...element}
                data={data}
                onClick={onClick}
                onChange={onChange}
                onBlur={onBlur}
            />
        );
    }

    if (element.type === "form_row") {
        return (
            <FormRow
                {...element}
                data={data}
                onClick={onClick}
                pending={pending}
                onChange={onChange}
                onBlur={onBlur}
                barClassName={barClassName}
            />
        );
    }

    if (element.type === "button_component") {
        return (
            <ButtonComponent
                {...element}
                pending={props.pending}
                onClick={onClick}
            />
        );
    }

    if (element.type == "dropdown_button") {
        return (
            <DropdownButton
                label={element.label}
                position="right"
                disabled={pending}
                pending={pending}
                items={element.elements}
                onSelect={onClick}
                icon={element.icon}
                className={className}
            />
        );
    }

    if (element.type == "dropdownCard") {
        return (
            <DropdownCard
                {...element}
                onBlur={onBlur}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onToggle={console.log}
                is_active={false}
            />
        );
    }

    if (element.type == "draggable_list") {
        return <DraggableList {...element} update={onBlur}/>;
    }

    if (element.type === "badge") {
        return <Badge {...element} />;
    }

    if (element.type === "new_media_picker") {
        return (
            <MediaPicker
                {...element}
                onChange={(name, media) => {
                    onChange && onChange(name, media);
                    onBlur && onBlur(name, media);
                }}
                onClear={(name) => {
                    onChange && onChange(name, null);
                    onBlur && onBlur(name, null);
                }}
                pending={pending}
                container={container}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            />
        );
    }

    if (element.type === "media_upload" && element.variant == "inline") {
        return (
            <FormMediaPicker
                value={element.defaultValue}
                {...element}
                media={element.defaultValue != "" ? {
                    type: "image",
                    value: element.defaultValue
                } : null}
                with_files={true}
                onChange={(type, file) => {
                    onChange && onChange(element.name, file);
                    onBlur && onBlur(element.name, file);
                }}
                onClear={() => {
                    onChange && onChange(element.name, "");
                    onBlur && onBlur(element.name, "");
                }}
            />
        );
    }

    if (element.type === "file_upload") {
        return (
            <FormFileUpload
                value={element.defaultValue}
                {...element}
                file={element.defaultValue != "" ? element.defaultValue : null}
                onChange={(type, file) => {
                    onChange && onChange(element.name, file);
                    onBlur && onBlur(element.name, file);
                }}
                onClear={() => {
                    onChange && onChange(element.name, "");
                    onBlur && onBlur(element.name, "");
                }}
            />
        );
    }

    if (element.type == "text_component") {
        return <Text {...element}>{element.text}</Text>;
    }

    if (element.type == "media_component") {
        return (
            <MediaComponent {...element} className={className}/>
        );
    }

    if (element.type == "table_media") {
        return (
            <MediaComponent
                {...element}
                className={`flex items-center justify-center object-cover text-center text-3xl ${element.size == "sm"
                    ? "size-8 rounded-full"
                    : "size-12 rounded-lg"
                }`}
            />
        );
    }

    if (element.type == "table_item") {
        return <TableItem {...element} />;
    }

    if (element.type == "avatar") {
        return (
            <PiggyTooltip {...element} place={"bottom"}>
                <MediaComponent
                    media={element.media}
                    className={"flex size-8 select-none rounded-full"}
                />
            </PiggyTooltip>
        );
    }

    if (element.type == "banner") {
        return <Banner {...element} />;
    }

    if (element.type == "info_banner") {
        return <InfoBanner {...element} />;
    }

    if (element.type == "link_component") {
        return <LinkComponent {...element}>{element.text}</LinkComponent>;
    }

    if (element.type == "spacer") {
        return <Spacer {...element} />;
    }

    if (element.type == "add_options") {
        return (
            <AddOptions
                isDisabled={element.isDisabled}
                onBlur={onBlur}
                options={element.options}
                setOptions={data}
            />
        );
    }

    if (element.type == "simple_table") {
        return <SimpleTable {...element} />;
    }

    if (element.type == "modal") {
        return (
            <Modal
                modal={element.content[0]}
                title={element.content[0].title}
                primaryButton={{
                    onClick: element.submit_button,
                }}
            >
                {element.content[0].content}
            </Modal>
        );
    }

    if (element.type == "filters_component") {
        return (
            <FilterComponentBuilder
                parentIdentifier={element.parent_identifier}
                canBeEdited={element.can_be_edited}
                emptyStateMessage={element.empty_state_message}
                onFilterUpdate={props.onUpdate}
            />
        );
    }

    if (element.type == "read_only_filters_component") {
        return (
            <ReadOnlyFiltersPreview
                parentIdentifier={element.parent_identifier}
                heading={element.heading}
            />
        );
    }

    if (element.type == "radio_element") {
        return <RadioElement {...element} onBlur={onBlur}/>;
    }

    if (element.type == "preview_component") {
        return (
            <PreviewComponent
                {...element}
                onChange={onChange}
                onBlur={onBlur}
            />
        );
    }

    // @TODO Rename to 'form_element_group' as it's used for both display and normal form elements. When doing so, don't forget to update the component in backend as well.
    if (element.type === "display_form_element_group") {
        return <FormElementGroup key={element.id} {...element} onBlur={onBlur}/>;
    }

    if (element.type === "table") {
        return (
            <Table
                table={element}
                pending={false}
                filterValues={filterValues}
                onFilterChange={onFilterChange}
            />
        );
    }

    if (element.type === "display_tag") {
        return (
            <DisplayTagElement {...element} value={value}/>
        );
    }

    if (element.type === "display_boolean") {
        return (
            <DisplayBooleanElement {...element} value={value}/>
        );
    }

    if (element.type === "display_media") {
        return (
            <DisplayMediaElement {...element} value={value}/>
        );
    }

    if (element.type === "display_text") {
        return (
            <DisplayFormElement {...element} value={value}/>
        );
    }

    return (
        <FormElement
            {...element}
            minAmount={element.min_amount}
            maxAmount={element.max_amount}
            onChange={onChange}
            onBlur={onBlur}
            pending={pending}
            container={container}
            barClassName={barClassName}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            mediaRenderer={mediaRenderer}
            onMouseUp={onMouseUp}
        />
    );
}
