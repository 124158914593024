import React from "react";
import FormGroup from "components/forms/FormGroup";
import { useTrans } from "components";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";
import DisplayBooleanElement from "components/forms/DisplayFormElements/DisplayBooleanElement";
import { Permission } from "@/models/accountUser/AccountUser";

export default function Permissions() {
    const { t } = useTrans();
    const accountUser = useCurrentAccountUser();

    return (
        <PageContent title={t("permissions")}>
            <ContentCard>
                <FormGroup>
                    <p className={"text-sm text-gray-500"}>
                        {t("currentRole")}: <span className={"font-semibold ml-0.5"}>{accountUser.role?.name}</span>
                    </p>
                    <div className={"mt-2"}>
                        {Object.keys(accountUser.permissions).map((permission: Permission) => {
                            return (
                                <DisplayBooleanElement
                                    key={permission}
                                    label={permission}
                                    value={accountUser.permissions[permission] ? t("true") : t("false")}
                                    underlying_value={accountUser.permissions[permission]}
                                />
                            );
                        })}
                    </div>
                </FormGroup>
            </ContentCard>
        </PageContent>
    );
}