import React from "react";
import { BrowserRouter } from "react-router";
import Router from "@/routing/Router";
import { ToastContainer } from "react-toastify";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import WithAnalytics from "analytics/WithAnalytics";
import { DragAndDropProvider } from "context/dragAndDrop/DragAndDropContext";
import { HelmetProvider } from "react-helmet-async";

export default function App() {
    // The Drag and drag provider is here since it needs te rendered outside out the browser router components.
    return (
        <DndProvider backend={HTML5Backend}>
            <DragAndDropProvider>
                <BrowserRouter>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover
                        closeButton
                    />
                    <HelmetProvider>
                        <WithAnalytics>
                            <Router/>
                        </WithAnalytics>
                    </HelmetProvider>
                </BrowserRouter>
            </DragAndDropProvider>
        </DndProvider>
    );
}
