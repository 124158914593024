import React, { lazy, Suspense, useState } from "react";
import { Route, Routes } from "react-router";
import Navigation from "@/components/navigation/Navigation";
import UserDetails from "@/modules/user/Overview";
import UsageModal from "@/components/modals/UsageModal";
import UnauthorizedModal from "@/components/modals/UnauthorizedModal";
import HomeOverview from "@/modules/home/Overview";
import { UsagesProvider } from "@/context/providers/UsagesProvider";
import CommandPalette from "@/modules/global/commandPalette/CommandPalette";
import { CommandPaletteContext } from "@/modules/global/commandPalette/CommandPaletteContext";
import { GiftcardProgramContext } from "@/routing/store/giftcards/GiftcardRoutes";
import { StripcardProgram, StripcardProgramContext } from "@/routing/store/stripcards/StripcardRoutes";
import useFetcher from "@/repositories/base/useFetcher";
import AccountUser from "models/accountUser/AccountUser";
import useCurrentAccount from "models/account/useCurrentAccount";
import { AccountUserContext } from "@/context/Context";
import User from "models/user/User";
import WebShop from "@/modules/webshop/WebShop";
import useAnalytics from "@/analytics/useAnalytics";
import { useLocalStorage } from "usehooks-ts";
import { GiftcardProgram } from "@/models/account/Account";

const LazyDataRoutes = lazy(() => import("../data/DataRoutes"));
const LazyAppsRoutes = lazy(() => import("../apps/AppsRoutes"));
const LazyStoreRoutes = lazy(() => import("../store/StoreRoutes"));
const LazyLoyaltyRoutes = lazy(() => import("../loyalty/LoyaltyRoutes"));
const LazySettingsRoutes = lazy(() => import("../settings/SettingsRoutes"));
const LazyAdvancedRoutes = lazy(() => import("../settings/AdvancedRoutes"));
const LazyMarketingRoutes = lazy(() => import("../marketing/MarketingRoutes"));
const LazyAutomationRoutes = lazy(() => import("@/routing/automation/AutomationRoutes"));
const LazyAutomationsOverview = lazy(() => import("@/modules/automations/Overview"));
const LazyPaymentsOverview = lazy(() => import("@/modules/payments/Overview"));

const LazyCRMRoutes = lazy(() => import("@/routing/crm/CRMRoutes"));

export default function SelectedAccountRoutes() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedGiftcardProgram, setSelectedGiftcardProgram] = useLocalStorage<GiftcardProgram | null>("selected_giftcard_program", null);
    const [selectedStripcardProgram, setSelectedStripcardProgram] = useLocalStorage<StripcardProgram | null>("selected_stripcard_program", null);
    const account = useCurrentAccount();
    const analytics = useAnalytics();
    const [accountUser, setAccountUser] = useState(new AccountUser());
    const _hsq = window._hsq || [];

    useFetcher({
        url: `accounts/${account.id}/account-user`,
        dataSetter: (data: any) => {

            const user = new User();

            const userData = data.user;

            user.id = userData.id;
            user.email = userData.email;
            user.locale = userData.locale;
            user.avatar = userData.avatar;
            user.username = userData.username;
            user.lastname = userData.lastname;
            user.firstname = userData.firstname;
            user.createdAt = userData.created_at;
            user.isVerified = userData.is_verified;
            user.preferred_date_format = userData.preferred_date_format;
            user.preferred_date_time_format = userData.preferred_date_time_format;

            user.isSystemAdmin = userData.is_system_admin;
            user.isSystemSuperAdmin = userData.is_system_super_admin;

            const accountUser = new AccountUser();

            accountUser.user = user;
            accountUser.role = data.role;
            accountUser.isAdmin = data.is_admin;
            accountUser.permissions = data.permissions;

            setAccountUser(accountUser);

            _hsq.push(["identify", {
                email: user.email
            }]);

            analytics.identifyUser(user.id.toString());
        }
    });

    return (
        <AccountUserContext.Provider value={accountUser}>
            <GiftcardProgramContext.Provider value={{
                selectedProgram: selectedGiftcardProgram,
                setSelectedProgram: setSelectedGiftcardProgram
            }}
            >
                <StripcardProgramContext.Provider value={{
                    selectedProgram: selectedStripcardProgram,
                    setSelectedProgram: setSelectedStripcardProgram
                }}
                >
                    <UsagesProvider>
                        <CommandPaletteContext.Provider value={{
                            isOpen: isOpen,
                            setIsOpen: setIsOpen
                        }}
                        >
                            <Navigation/>
                            <CommandPalette/>
                            <UsageModal/>
                            <UnauthorizedModal/>

                            <div className={"pt-16"} id={"main-page"}>
                                <Routes>
                                    <Route path="/webshop" element={<WebShop/>}/>
                                    <Route path="/home" element={<HomeOverview/>}/>
                                    <Route path="/user" element={<UserDetails/>}/>

                                    <Route path="crm">
                                        <Route
                                            path={"*"}
                                            element={
                                                <Suspense fallback={null}>
                                                    <LazyCRMRoutes/>
                                                </Suspense>
                                            }
                                        />
                                    </Route>

                                    <Route path="data">
                                        <Route
                                            path="*"
                                            element={
                                                <Suspense fallback={null}>
                                                    <LazyDataRoutes/>
                                                </Suspense>
                                            }
                                        />
                                    </Route>

                                    <Route path="apps">
                                        <Route
                                            path="*"
                                            element={
                                                <Suspense fallback={null}>
                                                    <LazyAppsRoutes/>
                                                </Suspense>
                                            }
                                        />
                                    </Route>

                                    <Route path="store">
                                        <Route
                                            path="*"
                                            element={
                                                <Suspense fallback={null}>
                                                    <LazyStoreRoutes/>
                                                </Suspense>
                                            }
                                        />
                                    </Route>

                                    <Route path="loyalty">
                                        <Route
                                            path="*"
                                            element={
                                                <Suspense fallback={null}>
                                                    <LazyLoyaltyRoutes/>
                                                </Suspense>
                                            }
                                        />
                                    </Route>

                                    <Route path="settings">
                                        <Route
                                            path="*"
                                            element={
                                                <Suspense fallback={null}>
                                                    <LazySettingsRoutes/>
                                                </Suspense>
                                            }
                                        />
                                    </Route>

                                    <Route path="advanced">
                                        <Route
                                            path="*"
                                            element={
                                                <Suspense fallback={null}>
                                                    <LazyAdvancedRoutes/>
                                                </Suspense>
                                            }
                                        />
                                    </Route>

                                    <Route path="marketing">
                                        <Route
                                            path="*"
                                            element={
                                                <Suspense fallback={null}>
                                                    <LazyMarketingRoutes/>
                                                </Suspense>
                                            }
                                        />
                                    </Route>

                                    <Route
                                        path={"automation"}
                                        element={
                                            <Suspense fallback={null}>
                                                <LazyAutomationsOverview/>
                                            </Suspense>
                                        }
                                    />
                                    <Route path="automation">
                                        <Route
                                            path="*"
                                            element={
                                                <Suspense fallback={null}>
                                                    <LazyAutomationRoutes/>
                                                </Suspense>
                                            }
                                        />
                                    </Route>

                                    <Route
                                        path="/payments"
                                        element={
                                            <Suspense fallback={null}>
                                                <LazyPaymentsOverview/>
                                            </Suspense>
                                        }
                                    />
                                </Routes>
                            </div>
                        </CommandPaletteContext.Provider>
                    </UsagesProvider>
                </StripcardProgramContext.Provider>
            </GiftcardProgramContext.Provider>
        </AccountUserContext.Provider>
    );
}
