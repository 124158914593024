import Icon from "components/icons/Icon";
import React, { useState } from "react";
import { useTrans } from "components";
import VerticalDots from "assets/svg/icons/verticalDots.svg?react";
import Text from "components/typography/Text";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";

interface Props {
    id: number,
    label: string,
    is_editable: boolean;
    elements?: [];
    buttons?: [];
    onRemove: (id: any) => void;
    update: (name: string, value: string) => void;
    pending: boolean;
}

export default function AttributeCard(props: Props) {
    const { onRemove, label, id, is_editable } = props;
    const { t } = useTrans();
    const [settingsActive, setSettingsActive] = useState(false);

    return (
        <div
            id={id.toString()}
            className={`draggable flex w-2/3 flex-col items-center rounded-lg bg-gray-100 p-4`}
        >
            <div
                className={`flex w-full cursor-pointer justify-between`}
                onClick={() => setSettingsActive(!settingsActive)}
            >
                <div className={'flex items-center gap-1'}>
                    <img
                        alt="hamburger"
                        className="h-6 w-5 cursor-grab"
                        src={VerticalDots}
                    />
                    <Text
                        color={'text-gray-900'}
                        size={'sm'}
                        weight={'light'}
                    >
                        {label}
                    </Text>
                </div>
                <div className={'relative flex items-center gap-3'}>
                    <Icon type={"chevronDown"} attr={'svg'} className={'h-6 w-9'}/>
                </div>
            </div>
            {settingsActive && (
                <div
                    className={`mt-2 flex max-h-80 w-full flex-col gap-2 overflow-hidden rounded-md bg-white p-2 transition-all ease-in-out`}
                >
                    {!is_editable && (
                        <div className={'cursor-default rounded-md border border-primary-200 bg-primary-25 p-2'}>
                            <Text color={'text-gray-400'} size={'sm'}>
                                {t('isPiggyDefinedEditingOptionsLimited')}
                            </Text>
                        </div>
                    )}

                    <div className={'flex items-center justify-between'}>
                        <ButtonComponent
                            button_style={ButtonStyle.Danger}
                            icon={"trash"}
                            onClick={() => onRemove(id)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
