import React, { useState } from "react";
import FormGroup from "components/forms/FormGroup";
import useHttp from "core/http/useHttp";
import useFetcher from "repositories/base/useFetcher";
import { useToast, useTrans } from "components";
import useOnChange from "components/hooks/forms/useOnChange";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import FormElement from "components/forms/FormElement";
import Form from "components/forms/Form";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";

export default function Password() {
    const { t } = useTrans();
    const http = useHttp();
    const { successToast, errorToast } = useToast();
    const [data, setData] = useState(null);
    const [payload, setPayload] = useState({});
    const onChange = useOnChange(setPayload);
    const [updatePending, setUpdatePending] = useState(false);
    const apiUrl = 'users/change-password';
    useFetcher({
        url: apiUrl,
        dataSetter: setData,
    });

    const update = () => {
        setUpdatePending(true);
        http.post(apiUrl, payload)
            .then(() => {
                successToast("Password changed.");
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    if (!data) {
        return null;
    }

    return (
        <PageContent title={t("password")}>
            <ContentCard>
                <Form onSubmit={update}>
                    <FormGroup>
                        {data.map((e: any) => (
                            <FormElement
                                key={e.id}
                                {...e}
                                onChange={onChange}
                            />
                        ))}

                        <ButtonComponent
                            button_style={ButtonStyle.Primary}
                            label={t("submit")}
                            type={"submit"}
                            pending={updatePending}
                        />
                    </FormGroup>
                </Form>
            </ContentCard>
        </PageContent>
    );
}