import * as React from "react";
import { DateRange, SelectRangeEventHandler } from "react-day-picker";
import { Calendar } from "@/components/v2/SelectionAndInput/calendar";

type Props = {
    dateRange: DateRange;
    onChange: SelectRangeEventHandler;
};

export default function DefaultCalendar(props: Props) {
    const { dateRange, onChange } = props;

    return (
        <Calendar
            initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            selected={dateRange}
            numberOfMonths={2}
            onSelect={onChange}
        />
    );
}
