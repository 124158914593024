import React from "react";
import { PlacesType, Tooltip, VariantType } from 'react-tooltip';
import QuestionIcon from "assets/svg/icons/question.svg?react";

interface Props {
    id: string
    content: string;
    children?: React.ReactNode;
    place?: PlacesType;
    tooltipVariant?: VariantType;
}

export default function PiggyTooltipV2(props: Props) {
    const {
        id,
        content,
        place = "bottom",
        tooltipVariant = "dark"
    } = props;

    return (
        <div>
            <a className={`anchor-${id}`}>
                {props.children ? (
                    props.children
                ) : (
                    <QuestionIcon/>
                )}
            </a>
            <Tooltip
                anchorSelect={`.anchor-${id}`}
                place={place}
                variant={tooltipVariant}
                className={'!z-50 !text-xs'} // You need to use important for the classNames to work
            >
                {content}
            </Tooltip>
        </div>
    );
}
